<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div class="pui-form-layout">
				<v-layout wrap>
					<v-flex xs12>
						<pui-field-set>
							<v-layout wrap>
								<v-flex xs12 md8>
									<pui-text-field
										:label="$t('paprogramaambiental.nombre')"
										v-model="model.nombre"
										toplabel
										:disabled="this.method === 'read'"
										required
										maxlength="100"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-select
										:label="$t('paprogramaambiental.campanya')"
										toplabel
										required
										clearable
										:disabled="!isCreatingElement"
										modelName="vlupcampanya"
										v-model="model"
										:itemsToSelect="[{ campanya: model.campanya }]"
										:modelFormMapping="{ campanya: 'campanya' }"
										itemValue="campanya"
										itemText="campanya"
									></pui-select>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
				<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
					<v-tab :key="0" :href="'#detail'">{{ $t('paprogramaambiental.detail-tab') }}</v-tab>
					<v-tab :key="1" :href="'#simulation'" v-if="!isCreatingElement">{{ $t('paprogramaambiental.simulation-tab') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel">
					<v-tab-item :key="0" :value="'detail'">
						<v-layout wrap class="pui-form-layout">
							<v-flex xs12>
								<pui-field-set>
									<v-layout wrap>
										<v-flex xs12 md8>
											<pui-select
												:label="$t('paprogramaambiental.agc')"
												toplabel
												required
												clearable
												:disabled="this.method === 'read'"
												modelName="agc"
												v-model="model"
												:itemsToSelect="[{ id: model.agc }]"
												:modelFormMapping="{ id: 'agc' }"
												itemValue="id"
												itemText="nombre"
												:fixedFilter="{
													groups: [],
													groupOp: 'and',
													rules: [{ field: 'campanya', op: 'eq', data: model.campanya }]
												}"
											></pui-select>
										</v-flex>
									</v-layout>
									<v-layout wrap>
										<v-flex xs12 md8>
											<pui-select
												:label="$t('paprogramaambiental.itinerario')"
												toplabel
												clearable
												required
												:disabled="this.method === 'read'"
												v-model="model.itinerarios"
												modelName="vlupitinerariotecnico"
												:itemsToSelect="this.searchItinerarios"
												attach="itinerarios"
												itemValue="id"
												itemText="nombre"
												:multiple="true"
												:itemTemplate="true"
												:selectTemplate="true"
												:key="searchItinerariosKey"
											>
												<template slot="theTemplate" slot-scope="{ item }">
													<label>{{ item.nombre + ' - ' }}</label>
													<label>{{ item.descripcion + ' - ' }}</label>
													<label>{{ item.nombrecultivo + ' - ' }}</label>
													<label>{{ item.nombresistemacultivo }}</label>
												</template>
												<template slot="selectionTemplate" slot-scope="{ item }">
													<v-chip label>
														<label>{{ item.nombre + ' - ' }}</label>
														<label>{{ item.descripcion + ' - ' }}</label>
														<label>{{ item.nombrecultivo + ' - ' }}</label>
														<label>{{ item.nombresistemacultivo }}</label>
													</v-chip>
												</template>
											</pui-select>
										</v-flex>
									</v-layout>
								</pui-field-set>
							</v-flex>
						</v-layout>
					</v-tab-item>
					<v-tab-item :key="1" :value="'simulation'">
						<v-flex xs12>
							<pui-field-set>
								<div v-if="this.itinerarios.length > 0">
									<v-layout wrap>
										<v-flex xs12 md12>
											<v-progress-circular
												indeterminate
												color="#7CB03B"
												v-if="loadingCsv"
												style="float: right"
												size="30"
											></v-progress-circular>
											<v-btn depressed color="secondary" toplabel style="float: right" @click="exportData()">{{
												$t('paprogramaambiental.downloadcsv-btn')
											}}</v-btn>
										</v-flex>
										<v-flex xs12 md12>
											<table style="width: 100%">
												<tr>
													<th v-for="(header, index) in headers" :key="index" v-show="!isHidden(header)">{{ header }}</th>
												</tr>
												<tr v-for="(iti, index) in calculatedItinerarios" :key="index">
													<template>
														<td>{{ iti.itinerarionombre }}</td>
														<td>{{ iti.cultivonombre }}</td>
														<td>{{ iti.superficieha | format }}</td>
														<td class="editable" contenteditable="true" @blur="onEdit($event, index)">
															{{ percentage[index] }}
														</td>
														<td>{{ iti.margenbruto | format }}</td>
														<td>{{ iti.consumoagua | format }}</td>
														<td>{{ iti.rendimiento | format }}</td>
														<td>{{ iti.consumontotal | format }}</td>
														<td>{{ iti.huellacarbono | format }}</td>
														<td hidden>{{ iti.consumonmineral | format }}</td>
														<td hidden>{{ iti.consumonorganico | format }}</td>
														<td hidden>{{ iti.exceson | format }}</td>
														<td hidden>{{ iti.consumop2o5mineral | format }}</td>
														<td hidden>{{ iti.consumop2o5organico | format }}</td>
														<td hidden>{{ iti.consumop2o5total | format }}</td>
														<td hidden>{{ iti.excesop2o5 | format }}</td>
														<td hidden>{{ iti.toxicidadaguadulce | format }}</td>
														<td hidden>{{ iti.toxicidadaguamarina | format }}</td>
														<td hidden>{{ iti.toxicidadhumana | format }}</td>
														<td hidden>{{ iti.consumogasoleo | format }}</td>
														<td hidden>{{ iti.consumoelectricidad | format }}</td>
														<td hidden>{{ iti.huellahidrica | format }}</td>
														<td hidden>{{ iti.huellahidricaazul | format }}</td>
														<td hidden>{{ iti.huellahidricaverde | format }}</td>
														<td hidden>{{ iti.huellahidricagris | format }}</td>
														<td hidden>{{ iti.waterstressindex | format }}</td>
													</template>
												</tr>
											</table>
										</v-flex>
										<v-flex xs12 md8>
											<br />
										</v-flex>
									</v-layout>
									<!-- <pui-datatable modelName="vpaitinerarioindicadores"></pui-datatable> -->
								</div>
								<div v-else>
									<v-progress-linear indeterminate color="#7CB03B" class="mb-0" v-if="loading"></v-progress-linear>
									<div v-else>No se han encontrado datos de simulación</div>
								</div>
							</pui-field-set>
						</v-flex>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'paprogramaambiental-form',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'paprogramaambiental',
			model: 'paprogramaambiental',
			saving: false,
			tabmodel: 'detail',
			headers: [
				'Itinerario',
				'Cultivo',
				'ha',
				'%',
				'Margen bruto (€)',
				'Consumo agua (m3)',
				'Producción (Kg)',
				'Consumo N (Kg)',
				'Huella carbono (kg CO2 eq)',
				'hidden_consumonmineral',
				'hidden_consumonorgánico',
				'hidden_exceson',
				'hidden_consumop2o5mineral',
				'hidden_consumop2o5organico',
				'hidden_consumop2o5total',
				'hidden_excesop2o5',
				'hidden_toxicidadaguadulce',
				'hidden_toxicidadaguamarina',
				'hidden_toxicidadterrestre',
				'hidden_toxicidadhumana',
				'hidden_consumogasoleo',
				'hidden_consumoelectricidad',
				'hidden_huellahidrica',
				'hidden_huellahidricaazul',
				'hidden_huellahidricaverde',
				'hidden_huellahidricagris',
				'hidden_waterstressindex',
				'hidden_aportenriego'
			],
			itinerarios: [],
			calculatedItinerarios: [],
			percentage: [],
			loading: true,
			loadingCsv: false,
			searchItinerarios: [],
			searchItinerariosKey: 1
		};
	},
	watch: {
		percentage: {
			handler: function () {},
			deep: true
		},
		itinerarios: {
			handler: function () {},
			deep: true
		},
		loading() {},
		loadingCsv() {}
	},
	filters: {
		format(value) {
			const val = (value / 1).toFixed(2).replace('.', ',');
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		}
	},
	methods: {
		isHidden(nameHeader) {
			return nameHeader.indexOf('hidden_') > -1;
		},
		calculateItinerarios() {
			return this.itinerarios.map((itinerario, index) => {
				const percentage = this.percentage[index];
				const margenbruto = percentage ? this.calculatePercentage(percentage, itinerario.margenbruto) : itinerario.margenbruto;
				const consumoagua = percentage ? this.calculatePercentage(percentage, itinerario.consumoagua) : itinerario.consumoagua;
				const rendimiento = percentage ? this.calculatePercentage(percentage, itinerario.rendimiento) : itinerario.rendimiento;
				const consumontotal = percentage ? this.calculatePercentage(percentage, itinerario.consumontotal) : itinerario.consumontotal;
				const huellacarbono = percentage ? this.calculatePercentage(percentage, itinerario.huellacarbono) : itinerario.huellacarbono;
				const consumonmineral = percentage ? this.calculatePercentage(percentage, itinerario.consumonmineral) : itinerario.consumonmineral;
				const consumonorganico = percentage ? this.calculatePercentage(percentage, itinerario.consumonorganico) : itinerario.consumonorganico;
				const exceson = percentage ? this.calculatePercentage(percentage, itinerario.exceson) : itinerario.exceson;
				const consumop2o5mineral = percentage
					? this.calculatePercentage(percentage, itinerario.consumop2o5mineral)
					: itinerario.consumop2o5mineral;
				const consumop2o5organico = percentage
					? this.calculatePercentage(percentage, itinerario.consumop2o5organico)
					: itinerario.consumop2o5organico;
				const consumop2o5total = percentage ? this.calculatePercentage(percentage, itinerario.consumop2o5total) : itinerario.consumop2o5total;
				const excesop2o5 = percentage ? this.calculatePercentage(percentage, itinerario.excesop2o5) : itinerario.excesop2o5;
				const toxicidadaguadulce = percentage
					? this.calculatePercentage(percentage, itinerario.toxicidadaguadulce)
					: itinerario.toxicidadaguadulce;
				const toxicidadaguamarina = percentage
					? this.calculatePercentage(percentage, itinerario.toxicidadaguamarina)
					: itinerario.toxicidadaguamarina;
				const toxicidadterrestre = percentage
					? this.calculatePercentage(percentage, itinerario.toxicidadterrestre)
					: itinerario.toxicidadterrestre;
				const toxicidadhumana = percentage ? this.calculatePercentage(percentage, itinerario.toxicidadhumana) : itinerario.toxicidadhumana;
				const consumogasoleo = percentage ? this.calculatePercentage(percentage, itinerario.consumogasoleo) : itinerario.consumogasoleo;
				const consumoelectricidad = percentage
					? this.calculatePercentage(percentage, itinerario.consumoelectricidad)
					: itinerario.consumoelectricidad;
				const huellahidrica = percentage ? this.calculatePercentage(percentage, itinerario.huellahidrica) : itinerario.huellahidrica;
				const huellahidricaazul = percentage
					? this.calculatePercentage(percentage, itinerario.huellahidricaazul)
					: itinerario.huellahidricaazul;
				const huellahidricaverde = percentage
					? this.calculatePercentage(percentage, itinerario.huellahidricaverde)
					: itinerario.huellahidricaverde;
				const huellahidricagris = percentage
					? this.calculatePercentage(percentage, itinerario.huellahidricagris)
					: itinerario.huellahidricagris;
				const waterstressindex = percentage ? this.calculatePercentage(percentage, itinerario.waterstressindex) : itinerario.waterstressindex;
				const aportenriego = percentage ? this.calculatePercentage(percentage, itinerario.aportenriego) : itinerario.aportenriego;
				return {
					...itinerario,
					margenbruto,
					consumoagua,
					rendimiento,
					consumontotal,
					huellacarbono,
					consumonmineral,
					consumonorganico,
					exceson,
					consumop2o5mineral,
					consumop2o5organico,
					consumop2o5total,
					excesop2o5,
					toxicidadaguadulce,
					toxicidadaguamarina,
					toxicidadterrestre,
					toxicidadhumana,
					consumogasoleo,
					consumoelectricidad,
					huellahidrica,
					huellahidricaazul,
					huellahidricaverde,
					huellahidricagris,
					waterstressindex,
					aportenriego
				};
			});
		},
		calculatePercentage(a, b) {
			return (a * b) / 100;
		},
		afterGetData() {
			if (!this.isCreatingElement && this.model.itinerarios.length > 0) {
				this.getItineriosSelected();
			}
			this.setSimulationData();
		},
		getItineriosSelected() {
			let rules = '[';
			for (var i = 0; i < this.model.itinerarios.length; i++) {
				rules += '{ "data":' + this.model.itinerarios[i].id + ', "field":"id", "op":"eq" }';
				if (i !== this.model.itinerarios.length - 1) {
					rules += ',';
				}
			}
			rules += ']';
			const url = '/puisearch';
			const body = {
				filter: {
					groupOp: 'or',
					groups: [null],
					rules: JSON.parse(rules)
				},
				model: 'vlupitinerariotecnico',
				rows: -1
			};

			this.$puiRequests.postRequest(url, body, (response) => {
				this.searchItinerarios = response.data.data;
				this.searchItinerariosKey += 1;
			});
		},
		setSimulationData() {
			const url = '/puisearch';
			const body = {
				filter: {
					groupOp: 'and',
					groups: [null],
					rules: [
						{
							data: this.model.id,
							field: 'id',
							op: 'eq'
						}
					]
				},
				model: 'vpaitinerarioindicadores',
				rows: -1
			};
			this.$puiRequests.postRequest(
				url,
				body,
				(response) => {
					this.itinerarios = response.data.data;
					this.calculatedItinerarios = response.data.data;
					// Para probar varios registros
					// for (var i = 0, len = 10; i < len; i++) {
					// 	this.itinerarios.push(this.itinerarios[1]);
					// }
					for (var i = 0, len = this.itinerarios.length; i < len; i++) {
						this.percentage.push(100);
					}
					this.loading = false;
				},
				() => {
					this.loading = false;
				}
			);
		},
		onEdit(evt, index) {
			var src = evt.target.innerText;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault();
			} else {
				this.percentage[index] = src;
				this.calculatedItinerarios = this.calculateItinerarios();
			}
		},
		exportData() {
			// const url = '/vpaitinerarioindicadores/export';
			// const body = {
			// 	model: 'vpaitinerarioindicadores',
			// 	exportTitle: 'vpaitinerarioindicadores',
			// 	exportType: 'csv',
			// 	filter: {
			// 		groupOp: 'and',
			// 		groups: [],
			// 		rules: [
			// 			{
			// 				data: this.model.id,
			// 				field: 'id',
			// 				op: 'eq'
			// 			}
			// 		]
			// 	},
			// 	order: [{ column: 'itinerario', direction: 'asc' }],
			// 	queryText: '',
			// 	queryFields: [],
			// 	queryFlexible: false,
			// 	queryLang: 'es'
			// };

			var html = document.querySelector('table').outerHTML;

			var fechahoy = this.$moment(Date.now()).format('DMMYYYY');
			var paName = this.model.nombre.replace(' ', '_');
			var filename = 'simulacion_pa_' + paName + '_' + fechahoy + '.csv';
			this.exportTableToCsv(html, filename);

			// this.loadingCsv = true;
			// const url = '/paprogramaambiental/downloadSimulation';
			// const body = {
			// 	id: this.model.id
			// };
			// this.$puiRequests.downloadFileRequest(
			// 	'get',
			// 	url,
			// 	body,
			// 	response => {
			// 		if (!response.isAxiosError) {
			// 			const link = document.createElement('a');
			// 			link.href = window.URL.createObjectURL(new window.Blob([response.data]));
			// 			link.download = pui9api.getFilenameFromHeaders(response.headers);
			// 			link.click();
			// 		} else {
			// 			const title =  this.$puiI18n.t('puiaction.notifyTitle');
			// 			const message = this.$t('paprogramaambiental.sinpermiso');
			// 			pui9api.notify.error(message, title);
			// 		}
			// 		this.loadingCsv = false;
			// 	},
			// 	() => {
			// 		this.loadingCsv = false;
			// 	}
			// );
		},
		downloadCsv(csv, filename) {
			var csvFile;
			var downloadLink;
			// eslint-disable-next-line no-undef
			csvFile = new Blob([csv], { type: 'text/csv' });
			downloadLink = document.createElement('a');
			downloadLink.download = filename;
			downloadLink.href = window.URL.createObjectURL(csvFile);
			downloadLink.style.display = 'none';
			document.body.appendChild(downloadLink);
			downloadLink.click();
		},
		exportTableToCsv(html, filename) {
			var csv = [];
			var rows = document.querySelectorAll('table tr');
			for (var i = 0; i < rows.length; i++) {
				const row = [];
				const cols = rows[i].querySelectorAll('td, th');

				for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText.replace('hidden_', ''));
				csv.push(row.join(';'));
			}

			this.downloadCsv(csv.join('\n'), filename);
		}
	}
};
</script>

<style lang="css" scoped>
table,
th,
td {
	border: 1px solid gainsboro;
	border-collapse: collapse;
}
th,
td {
	padding: 10px;
}
th {
	text-align: left;
}
.editable {
	background-color: #ffff9a;
}
</style>
